import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ecoRoutes } from '../router/routes';

const AppRouter = () => {
	const eco3Path = (routes) => {
		let path = null;
		routes.forEach((item) => {
			item.path === 'eco3/preview' ? (path = true) : (path = false);
		});

		return path;
	};

	return (
		<Switch>
			{ecoRoutes.map((route) => (
				<Route
					key={route.path}
					component={route.component}
					path={route.path}
					exact={route.exact}
				/>
			))}
			{eco3Path(ecoRoutes) ? (
				<Redirect to='/eco/preview' />
			) : (
				<Redirect to='/eco3/preview' />
			)}
		</Switch>
	);
};

export default AppRouter;
