import React from "react";
import {Divider} from "antd";

const chap1_Questions = {
    'Объекты, оказывающие умеренное негативное воздействие на окружающую среду относятся к следующей категории:' : [
        'single',
        [
            'Объекты I категории',
            'Объекты II категории',
            'Объекты III категории',
            'Объекты IV категории',
        ],
        'Объекты II категории',
    ],
    'К объектам IV категории относятся:' : [
        'single',
        [
            'объекты, оказывающие значительное негативное воздействие на окружающую среду и относящиеся к областям применения наилучших доступных технологий',
            'объекты, оказывающие умеренное негативное воздействие на окружающую среду',
            'объекты, оказывающие незначительное негативное воздействие на окружающую среду',
            'объекты, оказывающие минимальное негативное воздействие на окружающую среду',
        ],
        'объекты, оказывающие минимальное негативное воздействие на окружающую среду',
    ],
    'При установлении критериев, на основании которых осуществляется отнесение объектов, оказывающих негативное воздействие на окружающую среду, к соответствующей категории, учитываются следующие параметры:' : [
        'single',
        [
            'уровни воздействия на окружающую среду видов хозяйственной и (или) иной деятельности',
            'уровень токсичности, канцерогенные и мутагенные свойства загрязняющих веществ, содержащихся в выбросах, сбросах загрязняющих веществ, а также классы опасности отходов производства и потребления',
            'классификация промышленных объектов и производств',
            'особенности осуществления деятельности в области использования атомной энергии',
            'Все вышеперечисленные',
        ],
        'Все вышеперечисленные',
    ],
    'В целях государственного регулирования выбросов загрязняющих веществ в атмосферный воздух устанавливаются:' : [
        'multiply',
        [
            'предельно допустимые выбросы',
            'предельно максимальные выбросы',
            'предельно допустимые нормативы вредных физических воздействий на атмосферный воздух',
            'количественные нормативы выбросов',
            'технологические нормативы выбросов',
            'качественные показатели выбросов',
            'технические нормативы выбросов',
        ],
        [
            'предельно допустимые выбросы',
            'предельно допустимые нормативы вредных физических воздействий на атмосферный воздух',
            'технологические нормативы выбросов',
            'технические нормативы выбросов',
        ],
    ],
    'Выбросы загрязняющих веществ в атмосферный воздух на объектах I категории, осуществляются на основании:' : [
        'single',
        [
            'отчетности предприятия/организации',
            'комплексного экологического разрешения',
            'декларации о воздействии на окружающую среду',
            'акта о воздействии на окружающую среду',
        ],
        'комплексного экологического разрешения',
    ],
    'К объектам I категории, оказывающих значительное негативное воздействие на окружающую среду, относятся следующие виды деятельности:' : [
        'single',
        [
            'деятельность по добыче сырой нефти или природного газа',
            'деятельность по добыче руд и песков драгоценных металлов, оловянных руд, титановых руд, хромовых руд на россыпных месторождениях и (или) их подготовке',
            'деятельность по производству бумаги и (или) картона, фанеры, древесно-стружечных и древесно-волокнистых плит',
            'деятельность по производству текстильный изделий',
        ],
        'деятельность по добыче сырой нефти или природного газа',
    ],
    'Сроки представления Формы № 2-ТП (водхоз) «Сведения об использовании воды»:' : [
        'single',
        [
            'До 1 января после отчетного периода',
            'До 15 января после отчетного периода',
            'До 22 января после отчетного года',
            'Нет ограничений по срокам',
        ],
        'До 22 января после отчетного года',
    ],
    'Сроки предоставления Формы № 4-ОС «Сведения о текущих затратах на охрану окружающей среды и экологических платежах»' : [
        'single',
        [
            'До 1 января после отчетного периода',
            'До 15 января после отчетного периода',
            'До 22 января после отчетного года',
            'До 25 января после отчетного года',
            'Нет ограничений по срокам',
        ],
        'До 25 января после отчетного года',
    ],
    'Форма № 4-ОС «Сведения о текущих затратах на охрану окружающей среды и экологических платежах» предоставляют:' : [
        'single',
        [
            'юридические лица, имеющие очистные сооружения, осуществляющие природоохранные мероприятия, а также производящие плату за негативное воздействие на окружающую среду',
            'предприятия и их обособленные подразделения, имеющие стационарные источники выбросов вредных (загрязняющих) веществ в атмосферный воздух',
            'котельные, состоящие на балансе жилищно-коммунальных хозяйств, транспортных и других организаций',
            'все вышеперечисленные',
        ],
        'юридические лица, имеющие очистные сооружения, осуществляющие природоохранные мероприятия, а также производящие плату за негативное воздействие на окружающую среду',
    ],
    'К числу основных документов в области обращения с отходами на предприятии относятся:' : [
        'single',
        [
            'Документы по паспортизации отходов',
            'Копии аттестатов аккредитации лабораторий, выполнявших анализы и исследования для паспортизации отходов',
            'Проекты нормативов образования отходов и лимитов на их размещение',
            'Все вышеперечисленные',
        ],
        'Все вышеперечисленные',
    ],
}
const chap2_Questions = {
    'Структура ПЭК включает:' : [
        'single',
        [
            'ПЭК за соблюдением общих требований природоохранного законодательства',
            'ПЭК за охраной атмосферного воздуха',
            'ПЭК за охраной водных объектов',
            'ПЭК в области обращения с отходами',
            'ПЭК за охраной земель и почв',
            'Все вышеперечисленное',
        ],
        'Все вышеперечисленное',
    ],
    'Стационарные источники должны быть оснащены автоматическими средствами измерения и учета показателей выбросов на объектах:' : [
        'single',
        [
            '1 категории',
            '2 категории',
            '3 категории',
            '4 категории',
        ],
        '1 категории',
    ],
    'Срок создания системы автоматического контроля не может превышать :' : [
        'single',
        [
            'четыре года со дня получения или пересмотра комплексного экологического разрешения',
            'пять лет со дня получения или пересмотра комплексного экологического разрешения',
            'десять лет со дня получения или пересмотра комплексного экологического разрешения',
        ],
        'четыре года со дня получения или пересмотра комплексного экологического разрешения',
    ],
    'При осуществлении ПЭК за охраной атмосферного воздуха регулярному контролю подлежат параметры и характеристики, нормируемые или используемые при установлении нормативов предельно допустимых и временно согласованных выбросов:' : [
        'single',
        [
            'источников выделения загрязняющих веществ в атмосферу',
            'организованных и неорганизованных, стационарных и передвижных источников выбросов загрязняющих веществ в атмосферу',
            'установок очистки газов',
            'всех вышеперечисленных',
        ],
        'всех вышеперечисленных',
    ],
    'Не требуется расчёт нормативов допустимых выбросов для объектов:' : [
        'single',
        [
            'I категории НВОС',
            'II категории НВОС',
            'III категории НВОС',
            'IV категории НВОС',
        ],
        'IV категории НВОС',
    ],
    'Нормативы допустимых выбросов определяются для объектов I категории НВОС:' : [
        'single',
        [
            'Декларацией о воздействии на окружающую среду',
            'Комплексным экологическим разрешением',
            'Протоколом проверки допустимых выбросов',
            'Актом проверки допустимых выбросов',
        ],
        'Комплексным экологическим разрешением',
    ],
    'Выдачу комплексного экологического разрешения осуществляет:' : [
        'single',
        [
            'Федеральная служба по надзору в сфере природопользования',
            'Министерство природных ресурсов и экологии',
            'Комитет по природопользованию, охране окружающей среды и обеспечению экологической безопасности',
            'Федеральная служба по экологическому, технологическому и атомному надзору',
        ],
        'Федеральная служба по надзору в сфере природопользования',
    ],
    'В случае неизменности технологических процессов основных производств, качественных и количественных характеристик выбросов, сбросов загрязняющих веществ и стационарных источников декларация о воздействии на окружающую среду представляется:' : [
        'single',
        [
            'один раз в 3 года',
            'один раз в 5 лет',
            'один раз в 7 лет',
            'один раз в 10 лет',
        ],
        'один раз в 7 лет',
    ],
    'Установление временно разрешенных выбросов, допускается только:' : [
        'single',
        [
            'При наличие ежегодной отчетности по выбросам',
            'При наличии плана мероприятий по охране окружающей среды или программы повышения экологической эффективности',
            'При наличии протокола допустимых выбросов',
            'Во всех перечисленных случаях',
        ],
        'При наличии плана мероприятий по охране окружающей среды или программы повышения экологической эффективности',
    ],
    'Нормативы допустимых сбросов радиоактивных веществ в водные объекты для водопользователей утверждаются:' : [
        'single',
        [
            'Федеральной службой по надзору в сфере природопользования',
            'Министерством природных ресурсов и экологии',
            'Комитетом по природопользованию, охране окружающей среды и обеспечению экологической безопасности',
            'Федеральной службой по экологическому, технологическому и атомному надзору',
        ],
        'Федеральной службой по экологическому, технологическому и атомному надзору',
    ],
    'Федеральный квалификационный каталог отходов утвержден:' : [
        'single',
        [
            'Приказом Росприроднадзора от 22.05.2017 № 242',
            'Федеральным законом от 31.12.2017 N 503-ФЗ',
            'Приказом Минприроды РФ от 28.02.2018 №74',
            'Постановлением Правительства РФ от 13.09.2016 №913',
        ],
        'Приказом Росприроднадзора от 22.05.2017 № 242',
    ],
    'В целях стимулирования юридических лиц и индивидуальных предпринимателей, осуществляющих хозяйственную и (или) иную деятельность, к проведению мероприятий по снижению негативного воздействия на окружающую среду и внедрению наилучших доступных технологий при исчисления платы за негативное воздействие на окружающую среду по размещению отходов IV класса опасности, которые образовались в процессе обезвреживания отходов III класса опасности применяются следующие коэффициенты:' : [
        'single',
        [
            '0,5',
            '0,67',
            '0,49',
            '0,33',
        ],
        '0,49',
    ],
    'Плата за негативное воздействие на окружающую среду по итогам отчетного года вносится:' : [
        'single',
        [
            'не позднее 1-го марта года, следующего за отчетным периодом',
            'не позднее 1-го апреля года, следующего за отчетным периодом',
            'не позднее 1-го июня года, следующего за отчетным периодом',
            'сроки не регламентированы',
        ],
        'не позднее 1-го марта года, следующего за отчетным периодом',
    ],
    'Невнесение в установленные сроки платы за негативное воздействие на окружающую среду влечет наложение административного штрафа:' : [
        'single',
        [
            'на должностных лиц в размере от 1 000 до 3 000 рублей',
            'на должностных лиц в размере от 3 000 до 6 000 рублей',
            'на должностных лиц в размере от 5 000 до 10 000 рублей',
            'на должностных лиц в размере от 15 000 до 30 000 рублей',
        ],
        'на должностных лиц в размере от 3 000 до 6 000 рублей',
    ],
    'Невнесение в установленные сроки платы за негативное воздействие на окружающую среду влечет наложение административного штрафа: ' : [
        'single',
        [
            'на юридических лиц - от 10 000 до 50 000 рублей',
            'на юридических лиц - от 50 000 до 100 000 рублей',
            'на юридических лиц - от 100 000 до 200 000 рублей',
            'на юридических лиц - от 500 000 до 100 000 000 рублей',
        ],
        'на юридических лиц - от 50 000 до 100 000 рублей',
    ],
}
const chap3_Questions = {
    'Надзор как способ обеспечения законности состоит в:' : [
        'single',
        [
            'периодическом наблюдении специальных государственных органов за деятельностью подчиненных им органов и лиц с целью выявления нарушений законности',
            'постоянном и систематическом наблюдении специальных государственных органов за деятельностью не подчиненных им органов и лиц с целью выявления нарушений законности',
            'постоянном наблюдении специальных государственных органов за деятельностью не подчиненных им органов и лиц с целью выявления нарушений законности, целесообразности и эффективности подконтрольного объекта',
        ],
        'постоянном и систематическом наблюдении специальных государственных органов за деятельностью не подчиненных им органов и лиц с целью выявления нарушений законности',
    ],
    'На основании установленных критериев каким органом определяется перечень объектов, подлежащих федеральному государственному экологическому надзору?' : [
        'single',
        [
            'Росприроднадзор',
            'Министерство Природных Ресурсов',
            'Правительство Российской Федерации',
        ],
        'Правительство Российской Федерации',
    ],
    'Как часто проводятся плановые проверки?' : [
        'single',
        [
            'не чаще чем один раз в год',
            'не чаще чем один раз в три года',
            'не чаще чем один раз в пять лет',
        ],
        'не чаще чем один раз в три года',
    ],
    'Какие виды ответственности за нарушение законодательства Российской Федерации в области обращения с отходами установлены Федеральным законом от 24.06.1998 № 89-ФЗ «Об отходах производства и потребления»?' : [
        'single',
        [
            'Неисполнение или ненадлежащее исполнение законодательства Российской Федерации в области обращения с отходами должностными лицами и гражданами влечет за собой дисциплинарную, административную ответственность в соответствии с законодательством Российской Федерации',
            'Неисполнение или ненадлежащее исполнение законодательства Российской Федерации в области обращения с отходами должностными лицами и гражданами влечет за собой дисциплинарную, административную, уголовную или гражданско-правовую ответственность в соответствии с законодательством Российской Федерации',
            'Неисполнение или ненадлежащее исполнение законодательства Российской Федерации в области обращения с отходами должностными лицами и гражданами влечет за собой административную, уголовную ответственность в соответствии с законодательством Российской Федерации',
        ],
        'Неисполнение или ненадлежащее исполнение законодательства Российской Федерации в области обращения с отходами должностными лицами и гражданами влечет за собой дисциплинарную, административную, уголовную или гражданско-правовую ответственность в соответствии с законодательством Российской Федерации',
    ],
    'Какой вид социальной ответственности не существует?' : [
        'single',
        [
            'Моральная',
            'Политическая',
            'Административная',
        ],
        'Административная',
    ],
    'За нарушение законодательства в области охраны окружающей среды устанавливается:' : [
        'single',
        [
            'Имущественная и административная ответственность в соответствии с законодательством',
            'Имущественная, дисциплинарная, административная и уголовная ответственность в соответствии с законодательством',
            'Имущественная, дисциплинарная, гражданская, трудовая, административная и уголовная ответственность в соответствии с законодательством',
        ],
        'Имущественная, дисциплинарная, административная и уголовная ответственность в соответствии с законодательством',
    ],
    'Нарушение правил охраны окружающей среды при проектировании, размещении, строительстве, вводе в эксплуатацию и эксплуатации промышленных, сельскохозяйственных, научных и иных объектов наказывается:' : [
        'single',
        [
            'штрафом в размере до 120 000 рублей или в размере заработной платы или иного дохода осужденного за период до одного года',
            'штрафом в размере до 250 000 рублей или в размере заработной платы или иного дохода осужденного за период до одного года',
            'штрафом в размере до 500 000 рублей или в размере заработной платы или иного дохода осужденного за период до одного года',
            'штрафом в размере до 1 000 000 рублей или в размере заработной платы или иного дохода осужденного за период до одного года',
        ],
        'штрафом в размере до 120 000 рублей или в размере заработной платы или иного дохода осужденного за период до одного года',
    ],
    'Производство запрещенных видов опасных отходов, транспортировка, хранение, захоронение, использование или иное обращение радиоактивных, бактериологических, химических веществ и отходов, если эти деяния создали угрозу причинения существенного вреда здоровью человека или окружающей среде наказывается:' : [
        'single',
        [
            'принудительными работами на срок до двух лет',
            'принудительными работами на срок до пяти лет',
            'принудительными работами на срок до семи лет',
            'принудительными работами на срок до десяти лет',
        ],
        'принудительными работами на срок до двух лет',
    ],
    'Производство запрещенных видов опасных отходов, транспортировка, хранение, захоронение, использование или иное обращение радиоактивных, бактериологических, химических веществ и отходов, повлекшие по неосторожности смерть человека, либо массовое заболевание людей наказываются лишением свободы:' : [
        'single',
        [
            'до 3-х лет',
            'до 5-ти лет',
            'до 8 лет',
            'до 10 лет',
        ],
        'до 8 лет',
    ],
    'В Законе «Об охране окружающей среды» предусмотрены следующие виды экологических правонарушений, влекущих административную ответственность:' : [
        'single',
        [
            'несоблюдение стандартов, норм и иных нормативов качества окружающей природной среды',
            'невыполнение обязанностей по проведению государственной экологической экспертизы и требований, содержащихся в заключении экологической экспертизы, а также в предоставлении заведомо неправильных и необоснованных экспертных заключений',
            'нарушение экологических требований при планировании, технико-экономическом обосновании, проектировании, размещении, строительстве, реконструкции, вводе в эксплуатацию, эксплуатации предприятий, сооружений, технологических линий и иных объектов',
            'загрязнение окружающей природной среды и причинение вследствие этого вреда здоровью человека, растительному и животному миру, имуществу граждан и юридических лиц',
            'Все вышеперечисленные',
        ],
        'Все вышеперечисленные',
    ],
}

const single = (question, answers, valid, id, finalize, userAnswers, setUserAnswers) => {
    return (
        <>
            <form className='testing-form' key={id}>
                <h2>{id}. {question}</h2>
                {answers?.length
                    ? answers.map((ans, index) =>
                        <label key={ans + index}>
                            <input
                                type={"radio"}
                                value={ans}
                                name={question}
                                disabled={finalize}
                                onClick={() => {
                                    let newUserAnswers = Object.assign(userAnswers);
                                    newUserAnswers[id] = ans;
                                    setUserAnswers({...newUserAnswers});
                                }}
                            />
                            <span style={
                                finalize
                                    ? ans===valid
                                        ? {color: 'forestgreen'}
                                        : ans === userAnswers[id] && ans !== valid ? {color: 'red'} : {}
                                    : {}
                            }
                            >{ans}</span>
                        </label>)
                    : void(0)
                }

                {finalize && userAnswers[id] === '' &&
                    <h4 style={{color: 'red', textAlign: 'center'}}>Ни один из вариантов не был выбран!</h4>
                }
            </form>

            <Divider/>
        </>
    )
}
const multiply = (question, answers, valid, id, finalize, userAnswers, setUserAnswers) => {
    return (
        <>
            <form className='testing-form' key={id}>
                <h2>{id}. {question}</h2>
                {answers?.length
                    ? answers.map((ans) =>
                        <label>
                            <input
                                type={"checkbox"}
                                value={ans}
                                name={question}
                                disabled={finalize}
                                onClick={(event) => {
                                    let newUserAnswers = Object.assign(userAnswers);
                                    let newAns = newUserAnswers[id] ? newUserAnswers[id] : [];

                                    if(event.target.checked) {
                                        newAns.push(ans);
                                    } else {
                                        newAns.splice(newAns.indexOf(ans), 1);
                                    }

                                    newUserAnswers[id] = newAns;
                                    setUserAnswers({...newUserAnswers});
                                }}
                            />
                            <span style={
                                finalize
                                    ?valid.indexOf(ans) !== -1 && userAnswers[id].indexOf(ans) !== -1
                                    ? {color: 'green'}
                                    : valid.indexOf(ans) === -1 && userAnswers[id].indexOf(ans) !== -1
                                        ? {color: 'red'}
                                        : valid.indexOf(ans) !== -1 ? {color: 'green'} : {}
                                    : {}
                            }>{ans}</span>
                        </label>)
                    : void(0)
                }

                {finalize && userAnswers[id] === '' &&
                <h4 style={{color: 'red', textAlign: 'center'}}>Ни один из вариантов не был выбран!</h4>
                }
            </form>

            <Divider/>
        </>
    )
}

export {chap1_Questions, chap2_Questions, chap3_Questions, single, multiply};
